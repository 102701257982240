<template>
    <component :is="compName" v-bind="compProps" />
</template>

<script>

export default {
    name: 'ComponentsDesktop',
    components:{
        PDPDesktopBlackRabbit: () => import('@/components/ProductPage/Desktop-BlackRabbit/Index.vue'),
        CatalogDesktop: () => import("@/components/Catalog/Desktop/Index.vue"),
        BlocksBlackRabbit: () => import("@/components/Blocks/Desktop.vue"),
        HeaderLinks: () => import("@/components/DesktopHeader/HeaderLinks.vue"),
        BundleListDesktop: () => import("@/components/Catalog/BundleList/Desktop.vue"),
        NewIn: () => import("@/components/NewIn/NewIn.vue"),
        SocialProfile: () => import("@/components/Social/Profile.vue"),
        CategoryPage: () => import("@/components/CategoryPage/Index.vue"),
    },
    props:{
        // component name
        compName:{
            type: String
        },
        // component props
        compProps:{
            type: Object
        },
    },
    data(){
        return{
        }
    },
    // mounted() {
    //     console.log('compName', this.compName);
    // },
    // updated(){
    // },
    // created() {
    // },
    // destroyed() {
    // },
    // watch: {
    // },
    // computed: {
    // },
    // methods: {
    // }
}
</script>
